<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-headeer-icon-container">
        <PrimeButton class="c-circular-button mr-3" data-testId="close-creditnote" @click="routeToCreditnotesList">
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-3">{{ t("common.cancel") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>

  <div class="c-credit-note">
    <div class="grid">
      <div class="col-12 xl:col-6">
        <div class="c-card h-full">
          <div class="grid">
            <div class="col-12">
              <div class="flex justify-content-between flex-wrap align-items-center">
                <div class="pl-5">
                  <b>{{ t("creditnote.header") }}: </b>
                  <span data-testid="creditnote-number">{{ currentCreditNote.creditNoteNumber }}</span>
                </div>
                <div class="pr-2">
                  <b>{{ t("creditnote.list-headers.date-created") }}: </b>
                  <span data-testid="creditnote-registered">{{ d(currentCreditNote.creditNoteDate) }}</span>
                </div>
              </div>
            </div>
            <div class="col-12 xl:col-6">
              <div class="col-12">
                <ContactPerson
                  v-model:contactPerson="currentCreditNote.contactPerson"
                  :customerContacts="[]"
                  :customerType="CustomerType.B2C"
                  :disabled="true"
                />
              </div>
              <div class="col-12">
                <ContactEmail v-model:email="currentCreditNote.contactPerson.email" :disabled="true" />
              </div>
            </div>
            <div class="col-12 xl:col-6">
              <CustomerInfo :customer="currentCreditNote.orderedBy" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 xl:col-6">
        <div class="c-card h-full">
          <div class="formgrid grid">
            <div class="field col-12">
              <CreditReference
                v-model:creditReference="currentCreditNote.creditReference"
                :focusInput="true"
                :disabled="true"
              />
            </div>
            <div class="field col-12">
              <Comment v-model:comment="currentCreditNote.creditComment" :disabled="true" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 xl:col-12">
        <div class="c-card" v-if="currentCreditNote.creditNoteLines">
          <DataTable :value="currentCreditNote.creditNoteLines" class="c-compact-datatable" :stripedRows="true">
            <Column field="included" style="width: 50px">
              <template #header>
                <Checkbox :binary="true" v-model="checked" disabled />
              </template>
              <template #body>
                <Checkbox :binary="true" v-model="checked" disabled />
              </template>
            </Column>

            <Column field="productNumber" class="w-10rem" :header="t('creditnote.headers.productNumber')" />
            <Column field="productName" :header="t('creditnote.headers.productName')">
              <template #body="{ data, field }">
                {{ data.isNoReferenceLine ? data.noReference : data[field] }}
              </template>
            </Column>
            <Column field="invoiceNumber" class="text-right w-10rem" :header="t('creditnote.headers.invoiceNumber')" />
            <Column field="quantity" class="text-right w-3rem" :header="t('creditnote.headers.quantity')" />
            <Column field="price" class="text-right w-8rem" :header="t('creditnote.headers.listPrice')">
              <template #body="{ data, field }">
                {{ n(data[field], "decimal") }}
              </template>
            </Column>
            <Column field="sumLine" class="text-right w-8rem" :header="t('creditnote.headers.sumToCredit')">
              <template #body="{ data, field }">
                {{ n(data[field], "decimal") }}
              </template>
            </Column>
          </DataTable>
          <CreditNoteFooter :totalSum="currentCreditNote.totalSum" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import router from "@/router/router";
import { useRoute } from "vue-router";
import CustomerInfo from "@/components/credit-note/CustomerInfo.vue";
import Comment from "@/components/credit-note/Comment.vue";
import CreditReference from "@/components/credit-note/CreditReference.vue";
import ContactPerson from "@/components/credit-note/ContactPerson.vue";
import { useCreditNoteViewStore } from "@/stores/CreditNoteViewStore";
import { storeToRefs } from "pinia";
import ContactEmail from "@/components/credit-note/ContactEmail.vue";
import CreditNoteFooter from "@/components/credit-note/CreditNoteFooter.vue";
import { CustomerType } from "@/models/customer/CustomerType";

const { t, n, d } = useI18n();
const checked = ref(true);

const creditNoteId = ref("");
const route = useRoute();
const creditNoteViewStore = useCreditNoteViewStore();
const { currentCreditNote } = storeToRefs(creditNoteViewStore);

const routeToCreditnotesList = () => {
  router.push({ name: "credit-note-list" });
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    routeToCreditnotesList();
  } else return;
};

const getCreditNote = async () => {
  creditNoteId.value = route.params.id as string;
  await creditNoteViewStore.getCreditNoteById(creditNoteId.value);
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeyDown);
  await getCreditNote();
});
</script>
<style scoped lang="scss">
.c-credit-note {
  margin: var(--default-content-margin);
}
:deep(.p-inputtext) {
  &:enabled:focus {
    border: var(--floating-input-border-on-focus);
    box-shadow: none;
  }
}
</style>
