<template>
  <DataTable
    ref="productSearchResult"
    :value="creditableLinesFromProductsComputed"
    dataKey="id"
    :autoLayout="true"
    :lazy="true"
    :paginator="true"
    responsiveLayout="scroll"
    selectionMode="single"
    :sortOrder="sortOrder"
    :sortField="sortField"
    removableSort
    class="c-compact-datatable"
    stripedRows
    :loading="loading"
    data-testid="product-search-list"
    :totalRecords="totalHits"
    :rows="pageSize"
    :rowsPerPageOptions="[50, 100]"
    :currentPageReportTemplate="
      t('common.current-page-template', {
        first: '{first}',
        last: '{last}',
        totalRecords: '{totalRecords}',
      })
    "
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    scrollable
    scrollHeight="75vh"
    @sort="onSortChange"
    @page="onPageChange"
    @row-select="onRowSelected"
    @row-dblclick="onRowDblClicked"
  >
    <Column field="productNumber" :header="t('creditnote.product.product-number')" sortable> </Column>
    <Column field="gtin" :header="t('creditnote.product.gtin')" sortable> </Column>
    <Column field="name" :header="t('creditnote.product.product-number')" sortable> </Column>
    <Column field="quantityToCredit" :header="t('creditnote.product.quantity')" class="w-5rem p-0">
      <template #body="{ data, field, index }">
        <InputNumber
          :inputId="`product-quantity-to-credit-${index}`"
          :data-testid="`product-quantity-to-credit-${index}`"
          v-if="editingRowIndex === index && data[field] !== null"
          v-model="data[field]"
          :min="0"
          :max="Number.MAX_SAFE_INTEGER"
          :step="1"
          mode="decimal"
          inputClass="w-5rem text-right"
          :highlightOnFocus="true"
          @update:modelValue="updatePriceAndSum(data, $event, data.listPriceToCredit)"
          @keydown.enter.prevent="onAddNewCreditableRow(data)"
        />
      </template>
    </Column>
    <Column field="listPriceToCredit" :header="t('creditnote.product.credit-amount')" class="w-8rem p-0">
      <template #body="{ data, field, index }">
        <InputNumber
          :inputId="`price-to-credit-${index}`"
          :data-testid="`product-price-to-credit-${index}`"
          v-if="editingRowIndex === index && data[field] !== null"
          v-model="data[field]"
          :min="0"
          :max="Number.MAX_SAFE_INTEGER"
          :step="1"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          inputClass="w-8rem text-right"
          :highlightOnFocus="true"
          @update:modelValue="updatePriceAndSum(data, data.quantityToCredit, $event)"
          @keydown.enter.prevent="onAddNewCreditableRow(data)"
        />
      </template>
    </Column>
    <Column class="text-center p-0 w-3rem">
      <template #body="{ data, index }">
        <div class="w-3rem">
          <div class="c-product-search-edit">
            <div class="flex justify-content-center align-items-center">
              <PrimeButton
                v-if="editingRowIndex === index"
                text
                type="button"
                :data-testid="`product-list-add-${index}`"
                class="p-0"
                @click="onAddNewCreditableRow(data)"
              >
                <span class="c-row-add material-symbols-outlined material-filled"> add_circle </span>
              </PrimeButton>
            </div>
          </div>

          <div v-if="editingRowIndex !== index && isLineAdded(data)">
            <span class="c-product-search-used material-symbols-outlined material-filled"> check_circle </span>
          </div>
        </div>
      </template>
    </Column>
    <template #empty>
      {{ t("creditnote.product.no-products-found") }}
    </template>
  </DataTable>
</template>
<script setup lang="ts">
import {
  DataTablePageEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import { useI18n } from "vue-i18n";
import { SearchProduct } from "@/models/search/product/SearchProduct";
import { nextTick, ref } from "vue";
import { CreditableRow } from "@/models/credit-note/CreditableRow";
import { computed } from "vue";

const editingRowIndex = ref<number | null>(null);

const { t } = useI18n();

const props = defineProps<{
  creditableRows: CreditableRow[];
  products: SearchProduct[];
  page: number;
  loading: boolean;
  totalHits: number;
  sortOrder: number;
  pageSize: number;
  sortField: string;
}>();

const emit = defineEmits<{
  (e: "creditableRowAdded", value: CreditableRow): void;
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "update:sort", value: string): void;
}>();

const isLineAdded = (addedRow: CreditableRow) => {
  return props.creditableRows.some((row) => row.productId === addedRow.productId);
};

const creditableLinesFromProductsComputed = computed<CreditableRow[]>(() => {
  return props.products.map((product) => {
    return CreditableRow.createFromProduct(product);
  });
});

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  editingRowIndex.value = event.index;
  nextTick(() => {
    focusQuantityToCredit(event.index);
  });
};

const onRowDblClicked = (event: DataTableRowDoubleClickEvent) => {
  editingRowIndex.value = event.index;
  nextTick(() => {
    focusQuantityToCredit(event.index);
  });
};

const onSortChange = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }

    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const onPageChange = (event: DataTablePageEvent) => {
  emit("update:page", event.page + 1);
  emit("update:pageSize", event.rows);
};

const focusQuantityToCredit = (index: number) => {
  const el = document.getElementById(`quantity-to-credit-${index}`);
  if (el) {
    el.focus();
  }
};

const updatePriceAndSum = (row: CreditableRow, quantity: number | null, listPrice: number | null) => {
  row.sumToCredit = (quantity || 1) * (listPrice ?? 0);
  const max = Number.MAX_SAFE_INTEGER;
  if (row.sumToCredit > max) row.sumToCredit = max;
  if ((row.quantityToCredit || 1) * (row.listPriceToCredit ?? 0) > max) {
    row.listPriceToCredit = max / (row.quantityToCredit || 1);
  }
};

const onAddNewCreditableRow = (row: CreditableRow) => {
  editingRowIndex.value = null;
  emit("creditableRowAdded", row);
};
</script>
