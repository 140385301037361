<template>
  <PrimeDialog header="Update currency" v-model:visible="visible" :modal="true" class="c-add-dialog">
    <div class="formgrid grid px-5">
      <div class="field col-12 md:col-6">
        <SelectCurrencyIso v-model:currencyIso="currency.currencyIso" :currencyIsoOptions="isoOptions" />
      </div>

      <div class="field col-12 md:col-6">
        <div class="flex justify-content-end align-items-center h-full">
          <CurrencyState v-model:currencyState="currency.currencyState" />
        </div>
      </div>

      <div class="field col-12 md:col-6">
        <SelectUnit v-model:unit="currency.unit" />
      </div>

      <div class="field col-12 md:col-6">
        <BaseCurrency v-model:baseCurrencyIso="currency.baseCurrencyIso" :currencyIsoOptions="isoOptions" />
      </div>

      <div class="field col-12">
        <SelectCountry v-model:countryIso="currency.countryIso" :countries="props.countries" />
      </div>

      <div class="field col-12">
        <Description v-model:description="currency.description" />
      </div>
    </div>

    <div class="flex justify-content-end">
      <div v-tooltip.bottom="saveButtonTooltip">
        <PrimeButton
          class="c-circular-button c-box-shadow mr-2"
          @click="onUpdateCurrency"
          data-testid="btn-save-currency"
          :disabled="isSaving || !hasUpdates"
        >
          <ProgressSpinner v-if="isSaving" class="c-spinner" />
          <i v-else class="pi pi-check c-success-button c-circular-icon"></i>
          <span class="px-3">{{ t("common.save-changes") }}</span>
        </PrimeButton>
      </div>
      <PrimeButton
        class="c-circular-button c-box-shadow"
        @click="emit('update:showUpdateCurrencyDialog', false)"
        data-testid="btn-cancel-update-currency"
        :disabled="isSaving"
      >
        <i class="pi pi-times c-warning-button c-circular-icon"></i>
        <span class="px-3">{{ t("common.cancel") }}</span>
      </PrimeButton>
    </div>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { Country } from "@/models/country/Country";
import { Currency } from "@/models/currency/Currency";
import { useCurrencyService } from "@/api/currency/CurrencyService";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";

import SelectCurrencyIso from "./SelectCurrencyIso.vue";
import CurrencyState from "./CurrencyState.vue";
import SelectUnit from "./SelectUnit.vue";
import BaseCurrency from "./BaseCurrency.vue";
import SelectCountry from "./SelectCountry.vue";
import Description from "./Description.vue";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

const props = defineProps<{
  currency: Currency;
  showUpdateCurrencyDialog: boolean;
  countries: Country[];
}>();

const emit = defineEmits<{
  (e: "update:showUpdateCurrencyDialog", value: boolean): void;
}>();

const currency = ref<Currency>(cloneDeep(props.currency));

const { updateCurrency } = useCurrencyService();
const toast = useCumulusToast(useToast());
const isSaving = ref(false);
const { t } = useI18n();

const visible = computed<boolean>({
  get: () => props.showUpdateCurrencyDialog,
  set: (value) => emit("update:showUpdateCurrencyDialog", value),
});

const onUpdateCurrency = async () => {
  try {
    isSaving.value = true;
    await updateCurrency(currency.value);
    toast.add({
      severity: "success",
      summary: t("currency.save-currency-summary"),
      detail: t("currency.save-currency-detail", { iso: currency.value.currencyIso }),
    });
    emit("update:showUpdateCurrencyDialog", false);
  } finally {
    isSaving.value = false;
  }
};

const isoOptions = ref([
  { currencyIso: "NOK", countryIso: "NO" },
  { currencyIso: "SEK", countryIso: "SE" },
  { currencyIso: "DKK", countryIso: "DK" },
  { currencyIso: "GBP", countryIso: "GB" },
  { currencyIso: "USD", countryIso: "US" },
  { currencyIso: "EUR", countryIso: "EU" },
]);

const hasUpdates = computed(() => {
  return !isEqual(currency.value, props.currency);
});

const saveButtonTooltip = computed(() => {
  return hasUpdates.value ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>

<style scoped lang="scss">
.c-box-shadow {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.c-spinner {
  width: 22.83px;
}
</style>
