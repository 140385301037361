<template>
  <div class="col-12 xl:col-4">
    <div class="c-card h-full">
      <div class="grid">
        <div class="col-12 sm:col-6">
          <label for="c-economy-transfer-customer-name">
            {{ t("economy-transfer.supplier.name") }}
          </label>
        </div>
        <div class="col-12 sm:col-6">
          <span id="c-economy-transfer-customer-name" data-testid="c-economy-transfer-customer-name">{{
            supplier.name
          }}</span>
        </div>
        <div class="col-12 sm:col-6">
          <label for="c-economy-transfer-customer-number">
            {{ t("economy-transfer.supplier.number") }}
          </label>
        </div>
        <div class="col-12 sm:col-6">
          <span id="c-economy-transfer-customer-number" data-testid="c-economy-transfer-customer-number">{{
            supplier.supplierNumber
          }}</span>
        </div>
        <div class="col-12 sm:col-6">
          <label for="c-economy-transfer-customer-number">
            {{ t("economy-transfer.purchase-order-number") }}
          </label>
        </div>
        <div class="col-12 sm:col-6">
          <span id="c-economy-transfer-customer-number" data-testid="c-economy-transfer-customer-number">{{
            purchaseOrderNumber
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Supplier } from "@/models/economy-transfer/Supplier";
import { useI18n } from "vue-i18n";

defineProps<{
  supplier: Supplier;
  purchaseOrderNumber: string;
}>();

const { t } = useI18n();
</script>
