<template>
  <Dropdown
    v-model="accountGroupId"
    :options="accountGroups"
    optionLabel="name"
    optionValue="id"
    :placeholder="t('placeholder.select', { property: t('account-settings.account-group').toLocaleLowerCase() })"
    class="w-full"
    :class="{ 'p-invalid': val && val.$error }"
    :data-testid="dataTestId"
  />
  <small class="p-error" v-if="val && val.$error" :data-testid="`${dataTestId}-error`">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import { AccountGroup } from "@/models/account-group/AccountGroup";
import useVuelidate from "@vuelidate/core";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  isRequired?: boolean;
  dataTestId: string;
  accountGroups: AccountGroup[];
}>();

const accountGroupId = defineModel<string>("accountGroupId", {
  required: true,
});

const { t } = useI18n();

const rules = {
  accountGroupId: { required },
};

const val = props.isRequired ? useVuelidate(rules, { accountGroupId }) : undefined;
</script>
