<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <h1>{{ t("settings.header") }}</h1>
      <div class="c-header-icon-container"></div>
    </div>
  </div>
  <div id="open-picking-navbar-shortcuts" v-if="useTeleport">
    <Teleport to="#c-navbar-title">
      <h1>{{ t("settings.header") }}</h1>
    </Teleport>
    <Teleport to="#c-navbar-shortcuts">
      <div class="c-header-icon-container"></div>
    </Teleport>
  </div>
  <div class="c-settings">
    <div class="c-card">
      <div class="flex col-12" :class="{ 'mb-6 ': !panels.get('taxPanelCollapsed') }">
        <CumulusPanel
          toggleTestId="c-tax-panel"
          :collapsed="panels.get('taxPanelCollapsed')"
          @onCollapsedChange="panels.set('taxPanelCollapsed', $event)"
        >
          <template #title> {{ t("settings.tax.header") }} </template>
          <template #content>
            <TaxList />
          </template>
        </CumulusPanel>
      </div>

      <div class="flex col-12" :class="{ 'mb-6 ': !panels.get('paymentTermsPanelCollapsed') }">
        <CumulusPanel
          toggleTestId="c-payment-terms-panel"
          :collapsed="panels.get('paymentTermsPanelCollapsed')"
          @onCollapsedChange="panels.set('paymentTermsPanelCollapsed', $event)"
        >
          <template #title> {{ t("settings.payment-terms.header") }} </template>
          <template #content>
            <PaymentTermsList :card="false" :paginator="false" />
          </template>
        </CumulusPanel>
      </div>

      <div class="flex col-12" :class="{ 'mb-6 ': !panels.get('accountplanPanelCollapsed') }">
        <CumulusPanel
          toggleTestId="c-account-plan-panel"
          :collapsed="panels.get('accountplanPanelCollapsed')"
          @onCollapsedChange="panels.set('accountplanPanelCollapsed', $event)"
        >
          <template #title> {{ t("settings.account-plan.header") }} </template>
          <template #content>
            <AccountPlans></AccountPlans>
            <div class="c-under-construction"></div>
          </template>
        </CumulusPanel>
      </div>
      <div class="flex col-12" :class="{ 'mb-6 ': !panels.get('numberseriesPanelCollapsed') }">
        <CumulusPanel
          toggleTestId="c-number-series-panel"
          :collapsed="panels.get('numberseriesPanelCollapsed')"
          @onCollapsedChange="panels.set('numberseriesPanelCollapsed', $event)"
        >
          <template #title> {{ t("settings.number-series.header") }} </template>
          <template #content>
            <NumberSeries></NumberSeries>
          </template>
        </CumulusPanel>
      </div>
      <div class="flex col-12" :class="{ 'mb-6 ': !panels.get('currencyPanelCollapsed') }">
        <CumulusPanel
          toggleTestId="c-currency-panel"
          :collapsed="panels.get('currencyPanelCollapsed')"
          @onCollapsedChange="panels.set('currencyPanelCollapsed', $event)"
        >
          <template #title> {{ t("settings.currency.header") }} </template>
          <template #content> <CurrencyList /> </template>
        </CumulusPanel>
      </div>

      <div class="flex col-12" :class="{ 'mb-6 ': !panels.get('accountSetupPanelCollapsed') }">
        <CumulusPanel
          toggleTestId="c-account-setup-panel"
          :collapsed="panels.get('accountSetupPanelCollapsed')"
          @onCollapsedChange="panels.set('accountSetupPanelCollapsed', $event)"
        >
          <template #title> {{ t("settings.account-setup.header") }} </template>
          <template #content> <AccountSettingsList /> </template>
        </CumulusPanel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import { CumulusPanel } from "@cumulus/panel";
import PaymentTermsList from "@/components/payment-term/PaymentTermsList.vue";
import AccountPlans from "@/components/account-plan/AccountPlans.vue";
import NumberSeries from "@/number-series/components/NumberSeries.vue";
import CurrencyList from "@/components/currency/CurrencyList.vue";
import AccountSettingsList from "@/components/account-settings/AccountSettingsList.vue";
import { useClientStore } from "@/stores/ClientStore";
import { useAccountPlanStore } from "@/stores/AccountPlanStore";
import TaxList from "@/components/tax/TaxList.vue";
import { useTaxStore } from "@/stores/TaxStore";
import { usePaymentTermStore } from "@/stores/PaymentTermStore";

const useTeleport = !(import.meta.env.VITE_APP_STANDALONE === "true" || import.meta.env.MODE === "test");
const { t } = useI18n();
const { getClients } = useClientStore();
const { getAccountPlans } = useAccountPlanStore();
const { getTaxes } = useTaxStore();
const { getPaymentTerms } = usePaymentTermStore();

const panels = ref(
  new Map<string, boolean>([
    ["accountSetupPanelCollapsed", true],
    ["paymentTermsPanelCollapsed", true],
    ["taxPanelCollapsed", true],
    ["accountplanPanelCollapsed", true],
    ["numberseriesPanelCollapsed", true],
    ["currencyPanelCollapsed", true],
  ])
);

onMounted(() => {
  getClients();
  getAccountPlans();
  getTaxes();
  getPaymentTerms();
});
</script>

<style scoped lang="scss">
.c-settings {
  margin: var(--default-content-margin);
}
.c-settings .card {
  overflow: auto;
}
</style>
