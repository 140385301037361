<template>
  <PrimeDialog
    v-model:visible="visibleComputed"
    :header="t('creditnote.return-stock-dialog.header')"
    :modal="true"
    class="w-3"
  >
    <div class="formgrid grid">
      <div class="col-12 field">
        <label for="warehouse-dropdown">
          {{ t("creditnote.return-stock-dialog.warehouse") }}
        </label>
        <Dropdown
          id="warehouse-dropdown"
          :options="warehouseStore.warehouses"
          v-model="selectedWarehouseId"
          option-label="name"
          option-value="id"
          class="w-full"
        />
      </div>
      <div class="col-12 field">
        <label for="reason-comment">
          {{ t("creditnote.return-stock-dialog.reason") }}
        </label>
        <InputText
          id="reason-comment"
          type="text"
          v-model="comment"
          data-testid="reason-input"
          class="w-full inputfield"
        />
      </div>
      <div class="col-12">
        <DataTable :value="creditableRowsComputed">
          <Column field="returnToStock" :header="t('creditnote.return-stock-dialog.add')">
            <template #body="{ data, field }">
              <Checkbox v-model="data[field]" :binary="true" />
            </template>
          </Column>
          <Column field="productNumber" :header="t('creditnote.headers.productNumber')" />
          <Column field="quantityToCredit" :header="t('creditnote.headers.quantity')" />
          <Column field="listPriceToCredit" :header="t('creditnote.headers.listPriceToCredit')" />
          <Column field="positionNumber" :header="t('creditnote.return-stock-dialog.batchnumber')" />
        </DataTable>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-content-between flex-wrap card-container purple-container">
        <div class="flex align-items-center justify-content-center">
          <PrimeButton
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            @click="emit('update:visible', false)"
            class="c-secondary-btn-background"
            rounded
          />
        </div>
        <div class="flex align-items-center justify-content-center">
          <PrimeButton
            :label="t(`common.save`)"
            @click="createNewCreditNote"
            class="c-main-btn-background"
            :disabled="!creditableRows.some((x) => x.returnToStock)"
            data-testid="update-btn"
            rounded
          />
        </div>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { CreditableRow } from "@/models/credit-note/CreditableRow";
import { useWarehouseStore } from "@/stores/WarehouseStore";
import { ReturnProductInfo } from "@/models/credit-note/ReturnProductInfo";

const props = defineProps<{
  visible: boolean;
  creditableRows: CreditableRow[];
}>();

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
  (e: "saveCreditNote", value: ReturnProductInfo[]): void;
}>();

const { t } = useI18n();

const warehouseStore = useWarehouseStore();
const selectedWarehouseId = ref<string>("");
const comment = ref("");

const visibleComputed = computed<boolean>({
  get: () => props.visible,
  set: (value: boolean) => emit("update:visible", value),
});

const creditableRowsComputed = computed(() => {
  return props.creditableRows.filter(
    (x) => x.included && !x.isNoReferenceLine && x.quantityToCredit && x.quantityToCredit > 0
  );
});

const createNewCreditNote = async () => {
  const returnProducts: ReturnProductInfo[] = [];

  for (const row of creditableRowsComputed.value) {
    if (!row.returnToStock) {
      continue;
    }

    returnProducts.push({
      productId: row.productId,
      warehouseId: selectedWarehouseId.value,
      quantityToReturn: row.quantityToCredit || 0,
      comment: comment.value,
    });
  }

  emit("saveCreditNote", returnProducts);
};

onMounted(async () => {
  await warehouseStore.getWarehouses();
  selectedWarehouseId.value = warehouseStore.warehouses[0].id;
});
</script>

<style scoped lang="scss">
.c-main-btn-background {
  background-color: var(--action-btn-bg);
}

.c-secondary-btn-background {
  color: var(--text-color);
  background-color: var(--surface-a);
}
</style>
