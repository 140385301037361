<template>
  <div class="col-12 xl:col-4">
    <div class="c-card h-full">
      <div class="grid">
        <div class="col-12 sm:col-6">
          <label for="c-economy-transfer-customer-name">
            {{ t("economy-transfer.customer.name") }}
          </label>
        </div>
        <div class="col-12 sm:col-6">
          <span id="c-economy-transfer-customer-name" data-testid="c-economy-transfer-customer-name">{{
            customer.name
          }}</span>
        </div>
        <div class="col-12 sm:col-6">
          <label for="c-economy-transfer-customer-number">
            {{ t("economy-transfer.customer.number") }}
          </label>
        </div>
        <div class="col-12 sm:col-6">
          <span id="c-economy-transfer-customer-number" data-testid="c-economy-transfer-customer-number">{{
            customer.customerNumber
          }}</span>
        </div>
        <div class="col-12 sm:col-6">
          <label for="c-economy-transfer-customer-org-number">
            {{ t("economy-transfer.customer.org-number") }}
          </label>
        </div>
        <div class="col-12 sm:col-6">
          <span id="c-economy-transfer-customer-org-number" data-testid="c-economy-transfer-customer-org-number">{{
            customer.organizationNumber
          }}</span>
        </div>
        <div class="col-12 sm:col-6">
          <label for="c-economy-transfer-customer-phone-number">
            {{ t("economy-transfer.customer.phone-number") }}
          </label>
        </div>
        <div class="col-12 sm:col-6">
          <span id="c-economy-transfer-customer-phone-number" data-testid="c-economy-transfer-customer-phone-number">{{
            customer.phoneNumber
          }}</span>
        </div>
        <div class="col-12 sm:col-6">
          <label for="c-economy-transfer-customer-email">
            {{ t("economy-transfer.customer.email") }}
          </label>
        </div>
        <div class="col-12 sm:col-6">
          <span id="c-economy-transfer-customer-email" data-testid="c-economy-transfer-customer-email">{{
            customer.email
          }}</span>
        </div>
        <div class="col-12 sm:col-6">
          <label for="c-economy-transfer-customer-address">
            {{ t("economy-transfer.customer.address") }}
          </label>
        </div>
        <div class="col-12 sm:col-6">
          <div
            id="c-economy-transfer-customer-address"
            data-testid="c-economy-transfer-customer-address"
            v-for="addressline in customer.addressLines"
            :key="addressline"
          >
            {{ addressline }}
          </div>
          <div id="c-economy-transfer-customer-postal-code" data-testid="c-economy-transfer-customer-postal-code">
            {{ customer.postalCode }} {{ customer.city }},
            {{ customer.countryIso }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Customer } from "@/models/economy-transfer/Customer";
import { useI18n } from "vue-i18n";

defineProps<{
  customer: Customer;
}>();

const { t } = useI18n();
</script>
