<template>
  <div class="c-number-series-list">
    <DataTable
      :value="numberSeries"
      dataKey="id"
      :autoLayout="true"
      @row-dblclick="onRowDblClick"
      responsiveLayout="scroll"
      selectionMode="single"
      :loading="loading"
      stripedRows
      :paginator="false"
      :rows="3"
      class="c-compact-datatable"
      data-testid="c-number-series-list"
      resizableColumns
      columnResizeMode="expand"
      showGridlines
    >
      <Column
        :header="!numberSeries[0]?.type ? '' : t(`number-series.column.type-${numberSeries[0]?.type}`)"
        headerClass="w-3rem"
      >
        <template #body="{ data }">
          <span :data-testid="`type-${data.type}`">{{ !data?.type ? "" : t(`number-series.${data.type}`) }}</span>
        </template>
      </Column>

      <Column :header="t(`number-series.column.from-number`)" headerClass="w-2rem" bodyClass="text-right">
        <template #body="{ data }">
          <InputNumber
            inputClass="c-input-numbers"
            v-model="data.fromNumber"
            :aria-valuenow="data.fromNumber"
            inputId="integeronly"
            :readonly="true"
            @dblclick="onDblClickInput(data)"
            :data-testid="`from-${data.type}`"
          />
        </template>
      </Column>

      <Column :header="t(`number-series.column.to-number`)" headerClass="w-3rem" bodyClass="text-right">
        <template #body="{ data }">
          <InputNumber
            inputClass="c-input-numbers"
            v-model="data.toNumber"
            :aria-valuenow="data.toNumber"
            inputId="integeronly"
            :readonly="true"
            @dblclick="onDblClickInput(data)"
            :data-testid="`to-${data.type}`"
          />
        </template>
      </Column>

      <Column :header="t(`number-series.column.last-number`)" headerClass="w-3rem" bodyClass="text-right">
        <template #body="{ data }">
          <InputNumber
            inputClass="c-input-numbers"
            v-model="data.lastUsedNumber"
            :aria-valuenow="data.lastUsedNumber"
            inputId="integeronly"
            :readonly="true"
            @dblclick="onDblClickInput(data)"
            :data-testid="`last-${data.type}`"
          />
        </template>
      </Column>

      <Column headerClass="w-3rem" bodyClass="text-right max-w-4rem">
        <template #body="{ data }">
          <PrimeButton type="button" :data-testid="`edit-${data.type}`" @click="editNumberSeries(data)" text>
            <i class="pi pi-pencil"></i>
          </PrimeButton>
        </template>
      </Column>

      <template #empty>{{ t("number-series.empty-list") }} </template>
      <template #loading>
        <div>
          <div class="flex justify-content-center">
            <ProgressSpinner v-if="loading" aria-label="Loading" class="c-number-spinner" />
          </div>
          <div>{{ t("number-series.loading-list") }}</div>
        </div>
      </template>
    </DataTable>

    <DialogEditNumberSeries
      v-if="showEditDialog && dialogNumberSeries"
      v-model:showDialog="showEditDialog"
      :dialogHeader="dialogHeader"
      :numberSeries="dialogNumberSeries"
      @saveNumberSeries="emit('saveNumberSeries', $event)"
    >
    </DialogEditNumberSeries>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { NumberSeries } from "@/number-series/model/NumberSeries";
import { DataTableRowDoubleClickEvent } from "primevue/datatable";
import DialogEditNumberSeries from "./DialogEditNumberSeries.vue";

const { t } = useI18n();

const props = defineProps<{
  numberSeries: NumberSeries[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: "saveNumberSeries", value: NumberSeries): void;
}>();

const numberSeries = computed<NumberSeries[]>(() => props.numberSeries);

const dialogNumberSeries = ref<NumberSeries>();
const dialogHeader = ref<string>("");

const showEditDialog = ref(false);

const editNumberSeries = (value: NumberSeries) => {
  dialogHeader.value = t(`number-series.dialog.header-${value?.type}`);
  dialogNumberSeries.value = value;
  showEditDialog.value = true;
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  editNumberSeries(event.data);
};

const onDblClickInput = (data: NumberSeries) => {
  editNumberSeries(data);
};
</script>

<style scoped lang="scss">
.c-number-series-list {
  :deep(.c-input-numbers) {
    background: transparent;
    border: none;
    text-align: right;
    width: 8rem;

    &:focus {
      box-shadow: none;
    }
  }
}

.c-number-spinner {
  width: 2rem;
  height: 2rem;
}
</style>
