<template>
  <PrimeDialog
    v-model:visible="visibleComputed"
    :header="t('creditnote.return-stock-dialog.header')"
    :modal="true"
    class="c-edit-dialog"
  >
    <p>{{ t("creditnote.return-stock-dialog.should-return-stock") }}</p>
    <template #footer>
      <div class="flex justify-content-between flex-wrap card-container">
        <div class="flex align-items-center justify-content-center">
          <PrimeButton
            :label="t(`common.yes`)"
            @click="emit('returnInventoryClicked')"
            class="c-main-btn-background"
            data-testid="open-return-stock-dialog"
            rounded
          />
        </div>
        <div class="flex align-items-center justify-content-center">
          <PrimeButton
            @click="emit('saveCreditNoteClicked')"
            class="c-secondary-btn-background"
            data-testid="save-credit-note-btn"
            rounded
          >
            <ProgressSpinner v-if="isSaving" class="c-spinner" />
            <span class="w-7rem" v-else> {{ t(`common.no`) }}</span>
          </PrimeButton>
        </div>
        <div class="flex align-items-center justify-content-center">
          <PrimeButton
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            @click="emit('update:visible', false)"
            class="c-secondary-btn-background"
            rounded
          />
        </div>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const isSaving = ref(false);

const props = defineProps<{
  visible: boolean;
}>();

const visibleComputed = computed<boolean>({
  get: () => props.visible,
  set: (value: boolean) => emit("update:visible", value),
});

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
  (e: "returnInventoryClicked"): void;
  (e: "saveCreditNoteClicked"): void;
}>();
</script>

<style scoped lang="scss">
.c-main-btn-background {
  background-color: var(--action-btn-bg);
}

.c-secondary-btn-background {
  color: var(--text-color);
  background-color: var(--surface-a);
}

.c-spinner {
  width: 7rem;
  height: 1.2rem;
}
</style>
