<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <div
          v-tooltip.bottom="
            selectedInvoices.length === 0 ? { value: t('creditnote.create-from-invoices-disabled') } : ''
          "
        >
          <PrimeButton
            class="c-circular-button"
            :disabled="selectedInvoices.length === 0"
            @click="navigateToCreditNote"
            data-testId="btn-create-credit-note"
          >
            <i class="pi pi-plus c-success-button c-circular-icon"></i>
            <span class="px-3">{{ t("creditnote.create-from-invoices") }}</span>
          </PrimeButton>
        </div>

        <PrimeButton class="c-circular-button" @click="openCreditNotesList" data-testId="btn-navigate">
          <i class="pi pi-list c-default-button c-circular-icon"></i>
          <span class="px-3">{{ t("creditnote.header") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
  <div class="c-invoice" v-if="loadFailed">
    <div class="c-card">{{ t("invoice.load-failed") }}</div>
  </div>
  <div class="c-invoice" v-else>
    <div class="c-card">
      <Suspense>
        <InvoiceList
          v-model:selectedInvoices="selectedInvoices"
          @navigateToCreditNote="navigateToCreditNote"
        ></InvoiceList>
      </Suspense>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { Invoice } from "@/models/invoice/Invoice";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { useRouter } from "vue-router";
import InvoiceList from "./InvoiceList.vue";

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const loadFailed = ref(false);
const selectedInvoices = ref<Invoice[]>([]);

const router = useRouter();

const navigateToCreditNote = () => {
  const firstInvoice = selectedInvoices.value[0];
  const firstCustomerNumber = firstInvoice.orderedBy.customerNumber;
  const firstCurrencyIso = firstInvoice.currencyIso;

  const hasDifferentCustomer = selectedInvoices.value.some(
    (invoice) => invoice.orderedBy.customerNumber !== firstCustomerNumber
  );

  const hasDifferentCurrencyIso = selectedInvoices.value.some((invoice) => invoice.currencyIso !== firstCurrencyIso);

  let errorMessage = "";

  if (hasDifferentCustomer) {
    errorMessage = t("invoice.error-invoice-customer-mismatch");
  } else if (hasDifferentCurrencyIso) {
    errorMessage = t("invoice.error-invoice-currency-mismatch");
  }

  if (errorMessage) {
    toast.add({
      severity: "error",
      summary: t("common.an-error-occured"),
      detail: errorMessage,
      closable: true,
    });
  } else {
    router.push({
      name: "credit-note-create-from-invoices",
      query: { invoiceIds: selectedInvoices.value.map((x) => x.id) },
    });
  }
};

const openCreditNotesList = () => {
  router.push({ name: "credit-note-list" });
};
</script>
