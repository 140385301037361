<template>
  <div class="c-footer-container mt-4">
    <div class="c-creditnote-footer">
      <div class="flex flex-row-reverse flex-wrap mr-4">
        <div class="flex justify-content-between flex-wrap row-gap-1 column-gap-8">
          <div class="c-creditnote-total-column">
            <label>{{ t("creditnote.total-amount") }}</label>
            <span class="c-creditnote-order-total-summary" data-testid="credited-amount">{{
              n(totalSum, "decimal")
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

defineProps<{
  totalSum: number;
}>();

const { t, n } = useI18n();
</script>

<style scoped lang="scss">
.c-footer-container {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: var(--surface-a);
  z-index: 1;
  background-color: var(--portal-main-bg-color);

  .c-card {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: none;
    margin-top: 0;
    padding-top: 0.7rem;
  }
}

.c-creditnote-footer {
  background: var(--footer-order-color-bg);
  border-radius: 4px;
  width: 100%;
}
.c-creditnote-footer label {
  color: var(--footer-order-color-test);
  font-weight: normal;
}

.c-creditnote-total-column {
  display: flex;
  flex-direction: column;
  height: 4rem;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
</style>
