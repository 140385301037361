<template>
  <div class="c-economy-transfer">
    <div class="c-card">
      <Suspense>
        <EconomyTransferList
          :economyTransfers="economyTransfers"
          :loading="loading"
          :pageSize="100"
          @economyTransferDoubleClicked="routeToViewPage"
          @economyTransferRefresh="onRefreshList"
        />
      </Suspense>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useEconomyTransferService } from "@/api/economy-transfer/EconomyTransferService";
import { onMounted, ref } from "vue";
import EconomyTransferList from "@/components/economy-transfer/EconomyTransferList.vue";
import { EconomyTransferInfo } from "@/models/economy-transfer/EconomyTransferInfo";
import { useRouter } from "vue-router";

const economyTransfers = ref<EconomyTransferInfo[]>([]);
const loading = ref<boolean>(false);
const { getAllEconomyTransfers } = useEconomyTransferService();
const router = useRouter();

const fetchEconomyTransfers = async () => {
  try {
    loading.value = true;
    economyTransfers.value = await getAllEconomyTransfers();
  } finally {
    loading.value = false;
  }
};

const onRefreshList = async () => {
  loading.value = true;
  await fetchEconomyTransfers();
};

onMounted(() => {
  fetchEconomyTransfers();
});

const routeToViewPage = (economyTransfer: EconomyTransferInfo) => {
  router.push({ name: "economy-transfer-view", params: { id: economyTransfer.id } });
};
</script>

<style scoped lang="scss">
.c-economy-transfer {
  margin: var(--default-content-margin);
}
</style>
