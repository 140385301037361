<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t(`account-plan.dialog.header-edit`)"
    :modal="true"
    class="c-edit-dialog"
  >
    <div class="col-12">
      <div class="grid">
        <div class="field col-12">
          <div class="flex align-items-center">
            <div class="col-6">
              <Name v-model:name="accountPlanRef.accountName" />
            </div>
            <div class="col-6">
              <AccountNumber v-model:accountPlanAccountNumber="accountPlanRef.accountNumber" />
            </div>
          </div>
          <div class="col-6">
            <div class="col-6">
              <div class="col-6">
                <div class="field-checkbox">
                  <label for="account-plan-isActive" data-testid="account-plan-isActive-label">
                    {{ t(`account-plan.dialog.forOutgoing.label`) }}
                  </label>
                  <Checkbox
                    inputId="account-plan-forOutgoing"
                    v-model="accountPlanRef.forOutgoing"
                    :binary="true"
                    data-testid="c-account-plan-forOutgoing"
                    class="ml-2"
                  />
                </div>
                <div class="field-checkbox">
                  <label for="account-plan-isActive" data-testid="account-plan-isActive-label">
                    {{ t(`account-plan.dialog.forIncoming.label`) }}
                  </label>
                  <Checkbox
                    inputId="account-plan-forIncoming"
                    v-model="accountPlanRef.forIncoming"
                    :binary="true"
                    data-testid="c-account-plan-forIncoming"
                    class="ml-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-content-between flex-wrap card-container purple-container">
        <div class="flex align-items-center justify-content-center">
          <PrimeButton
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            @click="cancel"
            class="p-button-secondary"
          />
        </div>
        <div class="flex align-items-center justify-content-center">
          <PrimeButton
            :label="t(`common.update`)"
            data-testid="update-btn"
            @click="updateAccountPlan"
            class="p-button-success"
          />
        </div>
      </div>
    </template>
  </PrimeDialog>
</template>
<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import cloneDeep from "lodash.clonedeep";
import { useCumulusToast } from "@cumulus/toast";
import Name from "@/components/account-plan/Name.vue";
import AccountNumber from "@/components/account-plan/AccountNumber.vue";
import { useAccountPlanStore } from "@/stores/AccountPlanStore";
import { AccountPlan } from "@/models/account-plan/AccountPlan";

const { t } = useI18n();
const val = useValidate();
const toast = useCumulusToast(useToast());

const accountPlanRef = ref(new AccountPlan());
const { editAccountPlan } = useAccountPlanStore();
const props = defineProps<{
  showDialog: boolean;
  accountPlan: AccountPlan;
}>();

const updateAccountPlan = async () => {
  val.value.$touch();
  await val.value.$validate();
  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  try {
    await editAccountPlan(accountPlanRef.value);

    toast.add({
      severity: "success",
      summary: t("account-plan.toast.update-success.summary"),
      detail: t("account-plan.toast.update-success.detail", { name: accountPlanRef.value.accountName }),
      closable: true,
    });

    visible.value = false;
  } catch {
    toast.add({
      severity: "error",
      summary: t("account-plan.toast.error.summary"),
      detail: t("account-plan.toast.error.detail"),
      closable: true,
    });
  }
};

const cancel = () => {
  accountPlanRef.value = new AccountPlan();
  visible.value = false;
};

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
}>();
const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});
watch(visible, (newValue: boolean) => {
  if (newValue) {
    accountPlanRef.value = cloneDeep(props.accountPlan);
  }
});
</script>
