<template>
  <DataTable
    :value="economyTransferLines"
    class="c-compact-datatable"
    dataKey="id"
    :autoLayout="true"
    responsiveLayout="scroll"
    selectionMode="single"
    :loading="loading"
    stripedRows
    sortField="name"
    :sortOrder="1"
    :rows="pageSize"
    :rowsPerPageOptions="[20, 50, 100]"
    data-testid="c-account-settings-list"
    scrollable
    scrollHeight="75vh"
  >
    <Column field="accountName" class="c-margin-auto text-center" :header="t('economy-transfer.account-name')"></Column>
    <Column
      field="accountNumber"
      class="c-margin-auto text-center"
      :header="t('economy-transfer.account-number')"
    ></Column>
    <Column field="taxCode" class="c-margin-auto text-center" :header="t('economy-transfer.tax-code')"></Column>
    <Column field="kid" class="c-margin-auto text-center" :header="t('economy-transfer.kid')"></Column>
    <Column field="amount" class="c-margin-auto text-center" :header="t('economy-transfer.amount')">
      <template #body="{ data }">
        <span>{{ n(data.amount, "decimal") }}</span>
      </template>
    </Column>
    <Column field="accountType" class="c-margin-auto text-center" :header="t('economy-transfer.account-type')"></Column>
    <Column
      field="invoiceReference"
      class="c-margin-auto text-center"
      :header="t('economy-transfer.invoice-reference')"
    ></Column>
    <Column field="processName" class="c-margin-auto text-center" :header="t('economy-transfer.process-name')">
      <template #body="{ data }">
        <span>{{ data.processName ? t(`economy-transfer.processes.${data.processName.toLowerCase()}`) : "" }}</span>
      </template>
    </Column>
  </DataTable>
</template>

<script setup lang="ts">
import { EconomyTransferLine } from "@/models/economy-transfer/EconomyTransferLine";
import { useI18n } from "vue-i18n";

defineProps<{
  economyTransferLines: EconomyTransferLine[];
  loading: boolean;
  pageSize: number;
}>();

const { t, n } = useI18n();
</script>

<style lang="scss" scoped>
.c-compact-datatable :deep(.p-datatable-thead > tr > th.c-margin-auto > div > span) {
  margin: auto;
}
</style>
