<template>
  <TabView data-testid="add-credit-lines-tab">
    <TabPanel :header="t('creditnote.invoice-search')">
      <InvoiceSearch
        :selectedCustomerNumber="selectedCustomerNumber"
        :orderedById="orderedById"
        :creditableRows="creditableRows"
      />
    </TabPanel>
    <TabPanel :header="t('creditnote.no-invoice-ref')">
      <ProductSearch
        :creditableRows="creditableRows"
        @addWithoutReferenceClicked="emit('addWithoutReferenceClicked')"
      />
    </TabPanel>
  </TabView>
</template>

<script lang="ts" setup>
import { CreditableRow } from "@/models/credit-note/CreditableRow";
import InvoiceSearch from "./InvoiceSearch.vue";
import { useI18n } from "vue-i18n";
import ProductSearch from "./ProductSearch.vue";

defineProps<{
  selectedCustomerNumber: string;
  orderedById: string;
  creditableRows: CreditableRow[];
}>();

const emit = defineEmits<{
  (e: "addWithoutReferenceClicked"): void;
}>();

const { t } = useI18n();
</script>
