<template>
  <DataTable
    ref="contactTableRef"
    :value="contacts"
    scrollHeight="flex"
    class="c-compact-datatable"
    dataKey="id"
    selectionMode="single"
    @row-select="onRowSelect"
    :rowHover="true"
    data-testid="contact-search-list"
    sortField="name"
    :sortOrder="1"
    stripedRows
    autoLayout
    :paginator="true"
    :rows="10"
    :totalRecords="totalHits"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :currentPageReportTemplate="
      t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
    "
  >
    <template #header>
      <IconField iconPosition="left">
        <InputIcon class="pi pi-search" />
        <InputText
          id="contactSearchInput"
          ref="searchInputRef"
          v-model="query"
          v-debounce:200="search"
          :placeholder="t('creditnote.contact.search-placeholder')"
          @focus="selectAllOnFocus"
          @keydown.enter="searchInputEnterKey"
          @keypress.tab="focusFirstSearchResult"
          @keydown.down="focusFirstSearchResult"
          class="w-full"
          autocomplete="off"
          data-testid="contact-search-list-input"
        />
      </IconField>
    </template>

    <template>
      <Column field="firstName" :header="t('creditnote.contact.first-name')"> </Column>
      <Column field="lastName" :header="t('creditnote.contact.first-name')"> </Column>
      <Column field="phoneNumber" :header="t('creditnote.contact.phone')"> </Column>
      <Column field="email" :header="t('creditnote.contact.email')"> </Column>
      <Column field="isInvoiceRecipient" :header="t('creditnote.contact.invoice-recipient')" class="text-center">
        <template #body="{ data }">
          <i v-if="data.isInvoiceRecipient" class="pi pi-check-circle"></i>
        </template>
      </Column>
    </template>

    <template #empty>
      <div>
        {{ t("creditnote.contact.no-contacts-found") }}
      </div>
    </template>

    <template #footer> </template>
  </DataTable>
</template>

<script setup lang="ts">
import { ref, nextTick, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { DataTableRowSelectEvent } from "primevue/datatable";
import { CustomerContact } from "@/models/customer/CustomerContact";
import { CreditNoteContact } from "@/models/credit-note/CreditNoteContact";

const { t } = useI18n();

const props = defineProps<{ contact: CreditNoteContact; customerContacts: CustomerContact[] }>();
const emit = defineEmits<{ (e: "selectedContact", value: CustomerContact): void }>();

const contactTableRef = ref();

const focusFirstSearchResult = (event: Event) => {
  event.stopPropagation();
  contactTableRef.value.$el.querySelector("tbody tr:first-of-type").focus();
};

const searchInputEnterKey = (event: Event) => {
  event.stopPropagation();

  if (query.value.length < 1) {
    focusFirstSearchResult(event);
    return;
  }

  if (contacts.value.length === 1) {
    emit("selectedContact", contacts.value[0]);
    return;
  }

  if (contacts.value.length === 0) {
    const customerContact = new CustomerContact();
    const name = query.value.trim().split(" ");
    customerContact.lastName = name.length > 1 ? (name.pop() as string) : "";
    customerContact.firstName = name.join(" ");
    emit("selectedContact", customerContact);
  }

  focusFirstSearchResult(event);
  return;
};

const onRowSelect = async (event: DataTableRowSelectEvent) => {
  const supplierContact = event.data as CustomerContact;
  if (!supplierContact) return;

  emit("selectedContact", supplierContact);
};

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const searchInputRef = ref();
nextTick(() => {
  searchInputRef.value.$el.focus();
});

const totalHits = ref(0);
const query = ref<string>("");

const contacts = ref<CustomerContact[]>([]);
const search = async (q: string) => {
  if (q.length < 1 || q.trim() === "*") {
    contacts.value = props.customerContacts;
    totalHits.value = props.customerContacts.length;
    return;
  }
  query.value = q;

  contacts.value = props.customerContacts.filter(
    (c) =>
      c.firstName.toLowerCase().includes(q.toLowerCase()) ||
      c.lastName.toLowerCase().includes(q.toLowerCase()) ||
      (c.firstName.toLowerCase() + " " + c.lastName.toLowerCase()).includes(q.toLowerCase()) ||
      c.phoneNumber.toLowerCase().includes(q.toLowerCase()) ||
      c.email.toLowerCase().includes(q.toLowerCase())
  );

  totalHits.value = props.customerContacts.length;
};

onMounted(async () => {
  const name = props.contact?.firstName + " " + props.contact?.lastName;
  await search(name.trim());
});
</script>
