<template>
  <Dropdown
    v-model="paymentTermId"
    :options="filteredPaymentTerms"
    optionLabel="name"
    optionValue="id"
    :placeholder="
      t('placeholder.select', { property: t('account-settings.payment-terms.payment-term').toLocaleLowerCase() })
    "
    class="inputfield w-full"
    :data-testid="dataTestId"
    :class="{ 'p-invalid': val && val.$error }"
  />
  <small class="p-error" v-if="val && val.$error" data-testid="c-account-settings-payment-terms-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script lang="ts" setup>
import { required } from "@/locales/i18n-validators";
import { AccountSettingPaymentTerm } from "@/models/account-settings/AccountSettingPaymentTerm";
import { PaymentTerm } from "@/models/payment-term/PaymentTerm";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { computed } from "vue";

const props = defineProps<{
  paymentTerms: PaymentTerm[];
  paymentTermsSettings: AccountSettingPaymentTerm[];
  isRequired?: boolean;
  dataTestId?: string;
}>();

const paymentTermId = defineModel<string>("paymentTermId", {
  required: true,
});

const { t } = useI18n();

const filteredPaymentTerms = computed(() => {
  return props.paymentTerms.filter((paymentTerm) => {
    return !props.paymentTermsSettings.some((setting) => {
      return setting.id === paymentTerm.id && paymentTermId.value !== paymentTerm.id;
    });
  });
});

const rules = {
  paymentTermId: {
    required,
  },
};

const val = props.isRequired ? useVuelidate(rules, { paymentTermId }) : null;
</script>
